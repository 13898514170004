<template>
	<b-modal id="bus-captain-setup-modal"
		@close="close"
		size="lg"
		no-close-on-backdrop
		no-close-on-esc>
		<template #modal-title>
			Make Bus Captain
		</template>
		<div class="py-2">

			<div class="form mb-3">
				<div class="form-group mb-3">
					<label class="form-label">Select Route</label>
					<v-select
						v-model="form.route"
						class="form-group"
						placeholder="Search"
						:reduce="route => route.id"
						:options="routes"
						label="route_code"
						required>
						<template v-slot:option="route">
							<span >{{`${route.route_code ? route.route_code + ' - ': ''} From ${route.pickup} to ${route.destination}`}}</span>
						</template>
						<template v-slot:selected-option="route">
							<span >{{`${route.route_code ? route.route_code + ' - ': ''} From ${route.pickup} to ${route.destination}`}}</span>
						</template>
					</v-select>

				</div>

				<div class="form-group mb-3" v-if="!fetchingItineraries && itineraries.length">
					<label class="form-label">Assign Itinerary</label>
					<select class="form-control" v-model="form.itinerary">
						<option :value="null">-Select-</option>
						<option v-for="(option, index) in itineraries"
							:value="option.id"
							:key="index">{{option.trip_time}}</option>
					</select>
				</div>

				<div v-else class="form-group">
					<div class="d-flex align-items-center" v-if="fetchingItineraries">
						<div class="spinner-border spinner" role="status"></div>
						<p class="text-center m-0 loading-text"><span>Loading route itineraries...</span></p>
					</div>
					<p v-else-if="form.route">
						No itineraries available for selected route.
					</p>
					<p v-else>Please select a route</p>
				</div>

				<template v-if="form.itinerary">
					<template v-if="itineraryVehicles.length && !loadingItineraryVehicles">
						<div class="form-group">
							<label class="form-label">Select Vehicle</label>
							<v-select
								v-model="form.vehicle"
								class="form-group"
								:options="itineraryVehicles"
								label="name"
								:filterBy="vehicleFilter"
								required
								placeholder="Select a vehicle to assign"
								>
								<template v-slot:option="option">
									<p class="mb-2">
										{{ option.vehicle.brand }} •
										{{ option.vehicle.name }} {{ option.vehicle.registration_number }} •
										{{ option.vehicle.seats }} Seater
									</p>
									<p class="mb-0">Driver: {{ option.driver.fname }} {{ option.driver.lname }}</p>
								</template>
								<template v-slot:selected-option="option">
									<p class="mb-0">
										{{ option.vehicle.brand }} •
										{{ option.vehicle.name }} {{ option.vehicle.registration_number }} •
										{{ option.vehicle.seats }} Seater
									</p>
									<p class="mb-0 pl-3">Driver: {{ option.driver.fname }} {{ option.driver.lname }}</p>
								</template>
							</v-select>
						</div>
					</template>

					<template v-else>
						<div class="d-flex align-items-center" v-if="loadingItineraryVehicles">
							<div class="spinner-border spinner" role="status"></div>
							<p class="text-center m-0 loading-text"><span>Loading itinerary vehicles...</span></p>
						</div>
						<p v-else-if="form.itinerary">
							No vehicles attached to selected route itinerary.
						</p>
					</template>
				</template>

			</div>

		</div>
		<template #modal-footer>
			<div class="w-100">
				<b-button
					:disabled="processing || !isValidForm"
					variant="primary"
					class="float-right"
					@click="makeBusCaptain()">
					{{ processing ? 'Processing' : 'Save Changes' }}
				</b-button>
				<b-button
					:disabled="processing"
					variant="secondary"
					class="float-right mr-2"
					@click="close()">
					Cancel
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import busCaptainService from '@/api/bus_captain'
import routeResource from '@/api/route'
import { extractErrorMessage, filterVehicleBy } from '@/utils/helpers'

export default {
  name: 'BusCaptainSetup',
  props: {
    show: Boolean,
    userId: [Number, String]
  },
  data () {
    return {
      routes: [],
      itineraries: [],
      processing: false,
      fetchingItineraries: false,
      form: {
        route: null,
        itinerary: null,
        vehicle: null
      },
      loadingItineraryVehicles: false,
      itineraryVehicles: []
    }
  },
  watch: {
    'form.route' (value, oldValue) {
      if (value && value !== oldValue) {
        this.form.itinerary = null
        this.fetchRouteItineraries()
      }
    },
    'form.itinerary' (value, oldValue) {
      if (value && value !== oldValue) {
        this.form.vehicle = null
        this.fetchItineraryDrivers()
      }
    },
    show (value) {
      if (value) {
        this.showModal()
      } else {
        this.hideModal()
      }
    }
  },
  created () {
    this.fetchRoutes()
  },
  computed: {
    isValidForm () {
      return this.form.route && this.form.itinerary && this.form.vehicle
    }
  },
  methods: {
    fetchRoutes () {
      routeResource.listRoute({
        params: { status: 1, limit: 1000000 }
      }).then((res) => {
        this.routes = res
      }).catch(() => {

      })
    },

    fetchRouteItineraries () {
      this.itineraries = []
      this.fetchingItineraries = true
      this.axios.get(`/v1/routes/${this.form.route}/itineraries?itinerary_only=1`)
        .then((res) => {
          this.itineraries = res.data.data
        })
        .catch((err) => {
          
          this.$toastr.e('An error occurred.')
        })
        .finally(() => this.fetchingItineraries = false)
    },
    fetchItineraryDrivers () {
      this.loadingItineraryVehicles = true
      this.axios(`/v1/route-itineraries/${this.form.itinerary}/vehicles`).then((res) => {
        this.itineraryVehicles = (res.data.data || []).map((obj) => {
          return { ...obj, name: obj.vehicle?.name }
        })
      }).catch((e) => {
        this.$toastr.e(extractErrorMessage(e, e.message || 'Failed to fetch data'))
        this.close()
      }).finally(() => this.loadingItineraryVehicles = false)
    },
    vehicleFilter (option, label, search) {
      return filterVehicleBy(option?.vehicle, label, search)
    },
    makeBusCaptain () {
      const payload = {
        user_id: this.userId,
        route_itinerary_id: this.form.itinerary,
        route_vehicle_id: this.form.vehicle.id
      }
      this.processing = true
      busCaptainService.addAsBusCaptain(payload)
        .then(() => {
          this.$emit('close')
          this.$emit('changes-saved')
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Action successful!',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$toastr.e(extractErrorMessage(e, 'Failed to add user as bus captain'))
        })
        .finally(() => this.processing = false)
    },
    showModal () {
      this.$bvModal.show('bus-captain-setup-modal')
      this.form = {
        route: null,
        itinerary: null,
        vehicle: null
      }
    },
    close () {
      this.$emit('close')
    },
    hideModal () {
      this.$bvModal.hide('bus-captain-setup-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
$gray-2: #25292D;

.loading-text {
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 14px;
}

.spinner {
  width: 1rem;
  margin-right: 5px;
  height: 1rem;
  border-width: 0.15rem;
}

::v-deep .multiselect {
  & .multiselect__option--highlight {
    background: $gray-2;
    &:after {
      background: $gray-2;
    }
  }
}

::v-deep .v-select {

  &.vs--single {
    & .vs__selected {
      max-width: 80%;
    }
  }

  & .vs__search::placeholder {
    color: #D3DCE6;
    font-weight: 300;
    font-size: 0.875rem;
  }

  & .vs__dropdown-option {
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    white-space: normal;

    &:hover {
      background: $gray-2;
    }

    &--highlight, &--selected {
      background: $gray-2;
    }
  }
}

</style>
