<template>
	<div
		class="modal fade"
		id="debitWalletModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="debitWalletLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="wallet"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="debitWalletLabel">Debit Wallet</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<div class="row g-0 align-items-center justify-content-center">
						<div class="py-4 text-center">
							<h6 class="text-uppercase text-muted">Wallet Balance</h6>
							<h2 class="mb-0">₦{{ wallet.amount | money }}</h2>
						</div>
					</div>
					<form @submit.prevent="debitWallet">
						<div class="form-group">
							<label class="form-label">Debit Amount</label>
							<input
								v-model.trim="form.amount"
								required
								type="number"
								class="form-control"
								/>
						</div>
						<div class="form-group">
							<label for="description" class="col-form-label"
								>Debit Description</label
								>
							<textarea
								v-model.trim="form.description"
								class="form-control"
								id="description"
								placeholder="Enter debit description"
								required
								></textarea>
						</div>
						<div class="form-group">
							<label for="description" class="col-form-label">Account</label>
							<select
								class="form-select form-control mb-3"
								v-model.trim="form.source"
								required
								>
								<option value="main_balance">Main Balance</option>
								<option value="credit_balance">Company Balance</option>
							</select>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="debitWallet"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Debit Wallet' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#debitWalletModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    wallet: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        amount: '',
        description: '',
        source: 'main_balance'
      },
      processing: false,
      errorMessage: ''
    }
  },
  computed: {
    formValid () {
      return this.form.amount && this.form.source && this.form.description
    }
  },
  methods: {
    close (e) {
      const target = (e && e.target) || this.$refs.closeModalButton

      target.click()

      this.$emit('close')
    },
    debitWallet () {
      if (!this.formValid) {
        return
      }

      const walletBalance =
        this.form.source === 'main_balance'
          ? this.wallet.amount
          : this.wallet.credit_amount

      if (parseFloat(this.form.amount) > walletBalance) {
        return this.$swal({
          icon: 'error',
          title: 'Invalid Amount',
          text: 'Please enter an amount that is less than the wallet balance and try again',
          showCloseButton: true
        })
      }

      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/wallet/${this.wallet.id}/user/${this.wallet.user_id}`, {
          title: this.form.description,
          amount: this.form.amount,
          source: this.form.source,
          type: 'debit'
        })
        .then(() => {
          this.close()

          this.$swal({
            icon: 'success',
            title: 'Wallet Debit',
            text: `Wallet debit of ${this.$options.filters.money(
              this.form.amount
            )} has been completed successfully`,
            showCloseButton: true
          })

          this.$emit('wallet-funded')
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
