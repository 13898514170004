<template>
	<div
		class="modal fade"
		id="changeProfilePictureModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="changeProfilePictureLabel"
		aria-hidden="true"
		>
		<div class="modal-dialog modal-dialog-centered" role="document" v-if="user">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="changeProfilePictureLabel">
						Change Profile Picture
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form>
						<input
							type="file"
							accept="image/*"
							ref="imageInput"
							class="d-none"
							@change="prepareImageUpload"
							/>
						<div class="form-group">
							<label class="form-label mb-1">Upload Image</label>

							<small class="form-text text-muted">
								Select an image to upload
							</small>

							<div class="card">
								<div class="card-body">
									<div class="dropzone dz-clickable">
										<div
											class="
                        dz-preview
                        list-group list-group-lg list-group-flush
                        mt-4
                      "
											v-if="form.imageUri"
											>
											<img
												:src="form.imageUri"
												class="img-thumbnail img-fluid mx-auto"
												style="width: 200px; height: 200px"
												alt="Profile Image"
												/>
										</div>

										<button
											@click="openImageSelector"
											class="dz-default dz-message"
											>
											Click to select image
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="changeProfilePicture"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Upload' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#changeProfilePictureModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    user: Object,
    entityType: {
      type: String,
      default: 'user'
    }
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        imageUri: ''
      },
      processing: false,
      errorMessage: ''
    }
  }, 
  computed: {
    formValid () {
      return this.form.imageUri
    }
  },
  methods: {
    openImageSelector () {
      this.$refs.imageInput.click()
    },
    prepareImageUpload (event) {
      const fileReader = new FileReader()

      fileReader.onload = (e) => {
        this.form.imageUri = e.target.result
      }

      fileReader.readAsDataURL(event.target.files[0])
    },
 
    clearInput () {
      this.$refs.imageInput.value = ''
      this.form.imageUri = ''
    },
    changeProfilePicture () {
      if (!this.formValid) {
        return
      }

      this.processing = true
      this.errorMessage = ''
      const url = this.entityType === 'driver'
        ? `/v1/drivers/${this.user.id}`
        : `/v1/${this.entityType}/avatar/${this.user.id}`
      this.axios
        .patch(url, {
          avatar: this.form.imageUri
        })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Image Uploaded',
            text: 'Profile image has been uploaded successfully',
            showCloseButton: true
          })

          this.$emit('profile-updated')
		  this.$emit('close')
          this.close()
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
