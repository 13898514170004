<template>
  <div class="">
    <div v-if="loading">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
        <p class="text-center mt-4"><strong>Loading...</strong></p>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="card">
            <div class="card-header justify-content-between">
              <h4 class="card-header-title">User Information</h4>
              <div class="dropdown">
                <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-expanded="false">
                  Actions
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <router-link :to="{
                    name: 'ManageUser',
                    params: { userId: user.id },
                  }" class="dropdown-item" v-if="userHasPermission('users004')">Edit User</router-link>
                  <a class="dropdown-item" href="#" v-if="userHasPermission('users007')"
                    @click.prevent="showTopupWallet = true">Topup User Wallet</a>

                  <a class="dropdown-item" href="#" @click.prevent="showAddUserRoute = true"
                    v-if="userHasPermission('users010')">Book for user</a>
                  <a class="dropdown-item" href="#" @click.prevent="showChangePassword = true"
                    v-if="userHasPermission('users005')">Change Password</a>
                  <a class="dropdown-item" href="#" @click.prevent="showChangeProfilePicture = true"
                    v-if="userHasPermission('users004')">Add Profile
                    Picture</a>
                  <a class="dropdown-item" href="#" v-if="!isBusCaptain" @click.prevent="showBusCaptainSetup = true">
                    Make bus captain
                  </a>
                  <a class="dropdown-item" title="Add user as bus captain to another route" href="#" v-if="isBusCaptain"
                    @click.prevent="showBusCaptainSetup = true">
                    Add as bus captain
                  </a>
                  <a class="dropdown-item text-danger" href="#" v-if="userHasPermission('users008')"
                    @click.prevent="showDebitWallet = true">Debit User Wallet</a>
                  <a v-if="userHasPermission('users006')" class="dropdown-item"
                    :class="isUserActive ? 'text-danger' : 'text-success'" href="#"
                    @click.prevent="suspendOrUnsuspendUser">{{ isUserActive ? 'Suspend' : 'Unsuspend' }} User</a>
                  <a class="dropdown-item" :class="!isUserBlocked ? 'text-danger' : 'text-success'" href="#"
                    v-if="isUserBlocked" @click.prevent="unblockUser">Unblock User</a>
                </div>
              </div>
            </div>
            <div class="card-body p-2">
              <div class="list-group list-group-flush mb-4">
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Name</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ `${user.fname} ${user.lname}` }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Avatar</span>
                    </div>
                    <div class="col-auto">
                      <user-avatar :user="user" size="sm"></user-avatar>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Phone Number</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ user.phone }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Email Address</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ user.email }}</span>
                    </div>
                  </div>
                </div>

                <div class="list-group-item" v-if="isBusCaptain">
                  <div class="row align-items-center flex justify-between items-center">
                    <div class="col-4">
                      <span class="text-muted text-uppercase">Bus Captain</span>
                    </div>
                    <div class="col-8">
                      <div v-for="(route, index) in busCaptainRoutes" :key="index" class="bus-captain-list">
                        <div class="bus-captain-list__item">
                          <span @click="viewItinerary(route)" class="route-description"
                            :title="`${route.source} to ${route.destination}`">
                            <span class="code text-success">{{ route.route_code }}</span>
                            <span class="route-label">{{ route.source }} to {{ route.destination }}</span>
                          </span>
                          <i title="Click to remove user as bus captain"
                            class="fe fe-x text-danger cursor-pointer ml-2 font-weight-bold"
                            @click="removeAsBusCaptain(route)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Company</span>
                    </div>
                    <div class="col-auto">
                      <router-link v-if="user.company" :to="{name: 'ShowCompanyInfo', params: {companyId: user.corporate_id, status: 'active' }}"> {{ user.company.corporate_name}}  </router-link>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Car Owner</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{
                        user.car_owner == 1 ? 'Yes' : 'No'
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Gender</span>
                    </div>
                    <div class="col-auto">
                      <span class="" v-if="user.gender">{{
                        user.gender | titleCase
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Status</span>
                    </div>
                    <div class="col-auto">
                      <span :class="isUserActive ? 'text-success' : 'text-danger'">{{ isUserActive ? 'Active' :
                        'Inactive'
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 col-xl-4">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-header-title">User Wallet</h4>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div v-if="loadingUserWallet" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
              </div>
              <template v-else>
                <div class="row g-0 align-items-center justify-content-center">
                  <div class="col-auto">
                    <h1 class="display-3 mb-0">₦{{ wallet.amount | money }}</h1>
                  </div>
                </div>
                <div class="h5 mt-1 text-uppercase text-center text-muted mb-5">
                  Account Balance
                </div>
                <ul class="list-group list-group-flush">
                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Main Balance</small>
                    <small>₦{{ wallet.main_balance | money }}</small>
                  </li>
                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Company Balance ( Credit line )</small>
                    <small>₦{{ wallet.credit_amount || 0 | money }}</small>
                  </li>
                </ul>
              </template>
            </div>
          </div>

          <div v-if="user.company" class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-header-title">User's company wallet info ( Corporate pay ) </h4>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div v-if="loadingCorporateWalletUsage" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
              </div>
              <template v-else>
                <ul v-if="corporatePayUsage" class="list-group list-group-flush">
                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Wallet limit</small>
                    <small>{{ corporatePayUsage.limitInfo.limit_type == 'trip' ? `${corporatePayUsage.limitInfo.limit_value} trip(s)` : `N${corporatePayUsage.limitInfo.limit_value}.00` }} {{ corporatePayUsage.limitInfo.limit_value_unit }}</small>
                  </li>
                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Limit Usage</small>
                    <small>{{ corporatePayUsage.description }}</small>
                  </li>
                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Company</small>
                    <router-link :to="{name: 'ShowCompanyInfo', params: {companyId: user.corporate_id, status: 'active' }}"> {{ user.company.corporate_name}}  </router-link>
                  </li>

                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Company wallet balance</small>
                    <small>{{
                        userCorporateWalletDetails.wallet.available_balance_formatted
                      }}</small>
                  </li>

                  <li class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Is overdraft enabled</small>
                    <small>{{
                        userCorporateWalletDetails.wallet.supports_over_draw == 1 ? 'Yes' : 'No'
                      }}</small>
                  </li>

                  <li v-if="userCorporateWalletDetails.wallet.supports_over_draw == 1" class="
                      list-group-item
                      d-flex
                      align-items-center
                      justify-content-between
                      px-0
                    ">
                    <small>Overdraft balance</small>
                    <small>{{
                        userCorporateWalletDetails.wallet.max_over_draw_value
                      }}</small>
                  </li>

                </ul>
                <p v-else class="text-sm text-center font-medium py-3">No corporate pay</p>
              </template>
            </div>
          </div>
        </div>
      </div>
      <topup-wallet-modal :show="showTopupWallet" :wallet="wallet" @close="showTopupWallet = false"
        @wallet-funded="fetchUserWallet">
      </topup-wallet-modal>
      <debit-wallet-modal :show="showDebitWallet" :wallet="wallet" @close="showDebitWallet = false"
        @wallet-funded="fetchUserWallet">
      </debit-wallet-modal>
      <add-route-to-entity-modal :show="showAddUserRoute" :entity="user" @close="showAddUserRoute = false"
        @route-added="processRouteAdded">
      </add-route-to-entity-modal>
      <change-user-password-modal :show="showChangePassword" :user="user" @close="showChangePassword = false">
      </change-user-password-modal>
      <change-user-profile-picture-modal :show="showChangeProfilePicture" :user="user"
        @close="showChangeProfilePicture = false" @profile-updated="fetchUser">
      </change-user-profile-picture-modal>
      <bus-captain-setup :show="showBusCaptainSetup" v-if="user" :user-id="user.id" @close="showBusCaptainSetup = false"
        @changes-saved="refreshUserData" />
    </div>
  </div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import Swal from 'sweetalert2'
import TopupWalletModal from '@/components/modals/TopupWalletModal.vue'
import DebitWalletModal from '@/components/modals/DebitWalletModal.vue'
import AddRouteToEntityModal from '@/components/modals/AddRouteToEntityModal.vue'
import ChangeUserPasswordModal from '@/components/modals/ChangeUserPasswordModal.vue'
import ChangeUserProfilePictureModal from '@/components/modals/ChangeUserProfilePictureModal.vue'
import UserAvatar from '@/components/core/UserAvatar.vue'
import busCaptainService from '@/api/bus_captain'
import BusCaptainSetup from '@/components/modals/BusCaptainSetup'

export default {
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  },
  components: {
    BusCaptainSetup,
    TopupWalletModal,
    DebitWalletModal,
    AddRouteToEntityModal,
    ChangeUserPasswordModal,
    ChangeUserProfilePictureModal,
    UserAvatar
  },
  data() {
    return {
      user: null,
      migratingWallet: false,
      processingExecution: false,
      loading: true,
      errorLoading: false,
      currentCreditSystem: null,
      needCreditSystem: false,
      error: null,
      companies: null,
      wallet: null,
      loadingUserWallet: true,
      loadingCorporateWalletUsage: false,
      corporatePayUsage : null,
      loadingUserCompanyWalletDetails: false,
      userCorporateWalletDetails: null,
      errorLoadingCorporateWalletUsage: false,
      errorLoadingUserWallet: false,
      showTopupWallet: false,
      showDebitWallet: false,
      showAddUserRoute: false,
      showChangePassword: false,
      showChangeProfilePicture: false,
      showBusCaptainSetup: false,
      busCaptainData: null,
      busCaptainRoutes: []
    }
  },
  created() {
    this.init()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    isBusCaptain() {
      return !!this.user?.busCaptain || this.busCaptainRoutes?.length
    },
    isUserActive() {
      if (!this.user) {
        return false
      }

      return !!parseInt(this.user.active)
    },
    isUserBlocked() {
      return !!this.user.is_blocked
    }
  },
  methods: {
    async init() {
      this.loading = true
      this.errorLoading = false

      this.fetchUser()
        .then(() => {
          this.fetchUserWallet()
          this.fetchCorporateWalletUsage()
          this.fetchUserCompanyWalletDetails()
        })
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchUser() {
      return this.axios.get(`/v1/users/${this.userId}`)
        .then((res) => {
          this.user = res.data
          return this.axios.get(`v1/bus-captains/${this.user.id}/routes`)
        }).then((response) => {
          this.busCaptainRoutes = response.data || []
        })
    },
    async fetchCorporateWalletUsage() {
      try {
        if(!this.user.corporate_id){
          return
        }
        this.loadingCorporateWalletUsage = true
        const response = await this.axios.get(`v1/users/${this.user.id}/corporate-wallet-limit-usage`)
        if (response.data?.limitInfo?.id) {
          this.corporatePayUsage = response.data
        } else {
          this.corporatePayUsage = null
        }
      } catch (error) {
        if(error.response.data.message=='User does not belong to any company'){
          return
        }
      } finally {
        this.loadingCorporateWalletUsage = false
      }
    },
    async fetchUserCompanyWalletDetails(){
      try {
        if(!this.user.corporate_id){
          return
        }
        this.loadingUserCompanyWalletDetails = true
        const response = await this.axios.get(`/v1/corporates/${this.user.corporate_id}/wallets`)
        this.userCorporateWalletDetails = response.data.data[0]
      } catch (error) {
        const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'Unable to fetch company wallet details'

              this.$swal().showValidationMessage(msg)
      }
    },
    fetchUserWallet() {
      this.loadingUserWallet = true
      this.errorLoadingUserWallet = false

      this.axios
        .get(`/v1/users/${this.userId}/wallet`)
        .then((res) => {
          this.wallet = res.data.wallet
        })
        .catch(() => (this.errorLoadingUserWallet = true))
        .finally(() => (this.loadingUserWallet = false))
    },

    async unblockUser() {
      const isBlockedAction = this.isUserBlocked

      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${this.isUserBlocked ? 'unblock' : 'block'
          } this user?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/users/${this.userId}/blocked`, {
              isBlocked: !this.isUserBlocked
            })
            .then((res) => {
              this.user = res.data

              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `user has been ${isBlockedAction ? 'unblocked' : 'blocked'
              } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    async suspendOrUnsuspendUser() {
      const isSuspendAction = this.isUserActive

      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${this.isUserActive ? 'suspend' : 'unsuspend'
          } this user?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/users/${this.userId}`, {
              active: this.isUserActive ? 0 : 1
            })
            .then((res) => {
              this.user = res.data

              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `user has been ${isSuspendAction ? 'suspended' : 'unsuspended'
              } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    processRouteAdded() {
      this.fetchUserWallet()
      this.fetchUserTrips()
    },
    removeAsBusCaptain(busCaptainRoute) {
      busCaptainService
        .removeAsBusCaptain(busCaptainRoute.id, this.$swal,
          `${this.user.fname} ${this.user.lname}`,
          {
            successFxn: () => this.refreshUserData()
          }, `Are you sure you want to remove user from being a bus captain from this route ?
          ${busCaptainRoute.route_code} - ${busCaptainRoute.departure_time}`)
    },
    refreshUserData() {
      this.fetchUser()
    },
    viewItinerary(busCaptainRoute) {
      this.$router.push({
        name: 'ShowRouteItinerary',
        params: {
          routeId: busCaptainRoute.route_id,
          itineraryId: busCaptainRoute.route_itinerary_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.route-text {
  float: right;
  text-overflow: ellipsis;
}

.bus-captain-list {
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    .route-description {
      max-width: 80%;
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 500;
      cursor: pointer;

      .code {
        margin-right: 0.625rem;
      }

      .route-label {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0.5rem 0;
        font-size: 0.875rem;
        white-space: nowrap;
      }
    }
  }
}</style>
